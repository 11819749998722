import React from "react"
import WorkingOnLaptop from './person-on-laptop-working-on-laptop.json'
import loadable from '@loadable/component'

const Lottie = loadable(() => import("react-lottie-player"))

const Working = (props) => {
  const { width = 100, height = 100} = props
  return (
      <Lottie
        autoPlay
        play
        animationData={WorkingOnLaptop}
        autoplay
        style={{ height, width }}
      >
      </Lottie>
  )
}

export default Working;

