import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Working from '../animated/Working'

const GameNotReady = (props) => {
  return (
    <Fragment>
      <Grid container alignItems="center" justify="center" spacing={3}>
        <Grid item >
          <Box m={3}>
            <Typography align="center" variant="h4" component="h1" gutterBottom>Estamos trabajando en la información del juego</Typography>
            <Working width="200" height="200" />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  )
};


export default GameNotReady

